import { Product } from '@/types'
import { DiscountApplication, DiscountApplied } from '@/types/discount'
import {
  DISCOUNT_CONDITION_ALL,
  DISCOUNT_CONDITION_PRODUCT_TAG,
  DISCOUNT_CONDITION_PRODUCT_TYPE,
  CAMPAIGN_DISCOUNT_BLACKFRIDAY9,
} from '@/utils'
import { buildDiscountMessage, buildDiscountValue } from '@/utils/discount'
import {
  isProductHasTags,
  isProductHasType,
  isVariantSKUStartWiths,
} from '@/utils/product'
import useSettingStore from '@/store/setting'

export const useApplyFakePriceDiscountBF9 = () => {
  const settingStore = useSettingStore()

  function applyFakePriceDiscountBF9(
    product: Product,
    selectedVariantPrice: number,
    selectedVariantCompareAtPrice: number,
    variantSKU?: string
  ): DiscountApplied {
    selectedVariantPrice = +selectedVariantPrice
    selectedVariantCompareAtPrice = +selectedVariantCompareAtPrice

    const result: DiscountApplied = {
      amount: 0,
      amountFormated: '',
      messages: [],
      compareAtPrice:
        selectedVariantCompareAtPrice > selectedVariantPrice
          ? selectedVariantCompareAtPrice
          : selectedVariantPrice,
      price: selectedVariantPrice,
      applyDiscount(discountApplication: DiscountApplication) {
        // apply fake price
        if (discountApplication.fakePrice) {
          this.compareAtPrice =
            (this.compareAtPrice / 100) * (100 + discountApplication.fakePrice)
        }
        const discountAmountPercentage = Math.round(
          100 - this.price / (this.compareAtPrice / 100)
        )
        this.messages.push(
          buildDiscountMessage(
            discountApplication.valueType,
            discountAmountPercentage,
            `${discountAmountPercentage}% off`
          )
        )
        this.amountFormated = buildDiscountValue(
          discountApplication.valueType,
          discountAmountPercentage
        )
      },
    }

    const campaign = settingStore.campaigns?.find(
      (elm) => elm.code.toLowerCase() === CAMPAIGN_DISCOUNT_BLACKFRIDAY9
    )

    if (!campaign) return result
    const discountApplications = campaign.values

    // find the first applicable product based discount
    const selectedProductBasedDiscountApplication = discountApplications.find(
      (discountApplication) => {
        let isApplicable = false
        // loop in all the conditions and find if the product is qualified at least one of them
        for (let i = 0; i < discountApplication.conditions.length; i++) {
          const condition = discountApplication.conditions[i]
          switch (condition.applyFor) {
            case DISCOUNT_CONDITION_ALL:
              isApplicable = true
              break
            case DISCOUNT_CONDITION_PRODUCT_TAG:
              isApplicable = isProductHasTags(product, condition.value || [])
              break
            case DISCOUNT_CONDITION_PRODUCT_TYPE:
              isApplicable = isProductHasType(product, condition.value || [])
              break
            case DISCOUNT_CONDITION_VARIANT_SKU:
              isApplicable = isVariantSKUStartWiths(variantSKU, condition.value)
          }
          if (isApplicable) break
        }
        return isApplicable
      }
    )

    if (selectedProductBasedDiscountApplication) {
      result.applyDiscount(selectedProductBasedDiscountApplication)
    }

    if (result.compareAtPrice <= result.price) result.compareAtPrice = 0

    return result
  }

  return {
    applyFakePriceDiscountBF9,
  }
}
