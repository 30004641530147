<template>
  <template v-if="showTemporarilyUnavailablePage"
    ><TemporarilyUnavailable
  /></template>
  <template v-else>
    <div class="app__padding-top"></div>
    <AppProgressBar class="app__child" />
    <PromotionBar class="app__child"></PromotionBar>
    <Header></Header>
    <main class="router-view app__child">
      <!-- note for SEO
      - because there are no reactivity on the server side
       always render 404 block on the server. It can be removed later on entry-server
      - we just need to care for the content of non-404 page
    -->
      <!-- dummy tag, do nothing, just for replacing later  -->
      <dummy page-not-found-wrapper>
        <PageNotFound v-if="isThereAnyPageNotFound || isOnServer" />
      </dummy>
      <RouterViewTransition
        v-show="!isThereAnyPageNotFound"
        :ignore-first-load="false"
        :route-key="routePath"
      />
    </main>
    <BoxSubscribe class="app__child"></BoxSubscribe>
    <Footer class="app__child"></Footer>
    <template v-if="hasMarketingPopup && !isOnServer">
      <MarketingPopup />
    </template>
  </template>
</template>

<script lang="ts" setup>
import useSettingStore from './store/setting'
import useRouteStore from './store/route'
import useDiscount from './composables/applyDiscount'
import EventBus from './services/eventbus'
import Logger from './services/log'
import Analytics from '@/services/analytics'
import useDeviceStore from './store/device'
import useProductStore from './store/product'
import {
  computed,
  onMounted,
  onServerPrefetch,
  watch,
  defineAsyncComponent,
} from 'vue'
import {
  EVENT_APP_MOUNTED,
  EVENT_TRACKING_PAGE_HIDE,
  omitFalsyValue,
  EVENT_TRACKING_APP_MOUNTED,
  EVENT_TRACKING_PERFORMANCE_CLS,
  EVENT_TRACKING_PERFORMANCE_FID,
  EVENT_TRACKING_PERFORMANCE_LCP,
  EVENT_TRACKING_PERFORMANCE_FCP,
  EVENT_TRACKING_PERFORMANCE_TTFB,
  EVENT_TRACKING_USER_SCROLL,
  EVENT_USER_SUBSCRIBE,
  debounce,
  TIME_MARK_CSR_LOAD_PAGE,
  CSR_LOAD_TYPE,
  MIXPANEL_DESTINATION,
  destinationIntegrations,
  QUERY_STRING_GREETING_CARD,
  SEVEN_DAYS_IN_MILLISECONDS,
  removePropertiesStartWithPrefix,
  PRODUCT_TAG_APPLY_FAKE_PRICE_BF9_BUT_NO_DISCOUNT,
} from './utils'
import {
  AB_TEST_MEDIA_FLOW,
  AB_TEST_VARIANT_ENABLE,
  CACHE_KEY_CHECKOUT_ID,
  EVENT_ABTEST_ASSIGNED,
  EVENT_APP_PROGRESS_FINISH,
  EVENT_APP_PROGRESS_START,
  EVENT_TRACKING_NAVIGATION_ERROR,
  EVENT_USER_FIRST_INPUT,
  PAGE_NOT_FOUND_TITLE,
  QUERY_STRING_KOL_CAMPAIGN_CODE,
  SSR_CONTEXT_APPEND_TO_HEAD_FONT_PRELOAD_LINKS,
  TIKTOK_EXTERNAL_ID_TYPE,
} from '@/utils/constants'
import * as WebVitals from 'web-vitals'
import { useRoute, useRouter } from 'vue-router'
import { RouterViewTransition } from '@duannx/vue-router-transition'
import { appendToHead } from './composables/appendToHead'
import '@duannx/vue-router-transition/dist/style.css'
import './assets/styles/main.scss'
import SFProTextRegular from '@/assets/styles/fonts/FontsFree-Net-SFProText-Regular-1.woff2?url'
import Header from '@/components/header/Header.vue'
import Footer from '@/components/Footer.vue'
import BoxSubscribe from '@/components/BoxSubscribe.vue'
import AppProgressBar from '@/components/AppProgressBar.vue'
import PromotionBar from '@/components/PromotionBar.vue'
import useComponentStore from './store/component'
import PageNotFound from './pages/NotFound.vue'
import FreshchatService from './services/freshchat'

import { usePage } from './composables/page'
import { isOnServer } from './utils/ssr'
import useCollectionStore from './store/collection'
import usePageStore from './store/page'
import { useFakeFacebookPixel } from './composables/fakeFacebookPixel'
import { useABTest } from './composables/useABTest'
import useLandingPageStore from './store/landingPage'
import useBlogStore from './store/blog'

import { usePromotionBar } from './composables/usePromotionBar'
import { useUser } from './composables/useUser'
import CacheService, { getJSONfromCacheResponse } from './services/cache'
import useMediaCampaignStore from '@/store/media'
import LocationService, { UserLocation } from './services/location'
import useUserStore from '@/store/user'
import { CookieManager } from './services/cookie'
import useCheckoutStore from '@/store/checkout'
import { isProductHasTags } from '@/utils/product'

const userStore = useUserStore()
const checkoutStore = useCheckoutStore()
const mediaCampaignStore = useMediaCampaignStore()
const settingStore = useSettingStore()
const productStore = useProductStore()
const collectionStore = useCollectionStore()
const pageStore = usePageStore()
const landingPageStore = useLandingPageStore()
const blogStore = useBlogStore()
const route = useRoute()
const router = useRouter()
const routeStore = useRouteStore()
const deviceStore = useDeviceStore()
const componentStore = useComponentStore()
const { setPageTitle } = usePage()
const { applyDiscount } = useDiscount()
const { checkShouldFakeFBSale } = useFakeFacebookPixel()
const { runABTests, getABTestActivedVariantByCode } = useABTest()
const { showPromotionBarByQueryString } = usePromotionBar()
const { segmentingUser } = useUser()

const MarketingPopup = defineAsyncComponent(
  () => import('@/components/MarketingPopup.vue')
)
const TemporarilyUnavailable = defineAsyncComponent(
  () => import('./pages/TemporarilyUnavailable.vue')
)

const hasMarketingPopup = computed(
  () => settingStore.popups?.filter((popup) => popup.enable).length
)

// logic hidden all page for SIP store because of litigation reasons
// const showTemporarilyUnavailablePage = computed(
//   () =>
//     !deviceStore.isPhone &&
//     settingStore?.shop?.domain?.includes('silveryprints.com') &&
//     (!route.fullPath.includes('/pages/tracking') ||
//       !route.fullPath.includes('utm_source=email'))
// )
const showTemporarilyUnavailablePage = false

const maximumTimeToWaitFirstInput = 5000

const isThereAnyPageNotFound = computed(
  () =>
    productStore.isProductNotFound ||
    collectionStore.isCollectionNotFound ||
    pageStore.isPageNotFound ||
    landingPageStore.isLandingPageNotFound ||
    blogStore.isArticleBlogNotFound ||
    blogStore.isListArticleBlogNotFound ||
    mediaCampaignStore.isGreetingCardBlogNotFound
)

const routePath = computed(() => {
  let path = route.path
  // remove the last slash of the path to avoid case reload page
  // when vue-router automatically remove the last slash
  if (path.endsWith('/')) {
    path = path.substring(0, path.length - 1)
  }
  return path
})

// preload fonts
// just need to load the most used fonts here to prevent competing network with other critical resources
;[SFProTextRegular].forEach((font) => {
  appendToHead(
    {
      link: font,
      type: 'font/woff2',
    },
    SSR_CONTEXT_APPEND_TO_HEAD_FONT_PRELOAD_LINKS
  )
})

handleRouteError()

// the server prefetch hook in parent component will be always called first.
// so this hook is the first hook will be excuted
onServerPrefetch(async () => {
  if (!settingStore.shop) {
    await settingStore.loadSettings()
  }
  setPageTitle()
  runABTests()
  // segmenting user will be run on product component itself
  if (!route.path.includes('/products')) {
    segmentingUser()
  }
})

onMounted(async () => {
  EventBus.trigger(EVENT_APP_MOUNTED)
  //load shop setting
  if (!settingStore.shop) {
    await settingStore.loadSettings()
  }

  applyGreetingCardFlow()
  applyKolCampaign()

  // no apply discount when go to product page ssr mode and product has tag 'provipluxury'
  const hasFakePriceBF9ButNoDiscountProductTag = isProductHasTags(
    productStore.product,
    [PRODUCT_TAG_APPLY_FAKE_PRICE_BF9_BUT_NO_DISCOUNT]
  )
  if (!hasFakePriceBF9ButNoDiscountProductTag) {
    applyDiscount()
  }

  mockShopifyEnviroment()
  setCurrentUrl()

  watchShowHidePopup()
  watchShowHidePromotionBar()
  watchPageTitle()

  showPromotionBarByQueryString()
  useSSRAfterVisitingManyProductPages()
  toggleProgressBarOnRouterChange()
  handleNotFoundPage()
  checkShouldFakeFBSale()

  mesurePerformance()
  trackingPageEvents()
  // trackingPerformance()
  // trackUnloadEvents()
  // trackingPerformanceAppMounted()
  //trackingUserScroll()
  removeMixPanelCookie()
  removeFieldKlaIdCookie()
})

// identify user ip
Analytics.excuteOnReady(identifyUserIp)
function identifyUserIp() {
  LocationService.onLocationReady(async (location: UserLocation | null) => {
    if (location?.ip) {
      // handle user visit site from klaviyo
      const KLAVIYO_EXCHANGE_ID_QUERY = '_kx'
      const klaviyoExchangeId = route.query[KLAVIYO_EXCHANGE_ID_QUERY] as string
      let userKlaviyo: any = {}
      if (klaviyoExchangeId) {
        try {
          const store = import.meta.env.VITE_SEARCH_STORE

          userKlaviyo = await userStore.getUserKlaviyoByExchangeId(
            klaviyoExchangeId,
            store
          )
          if (userKlaviyo?.checkoutId) {
            checkoutStore.shouldRecoveryCheckoutIdBaseOnEmailKlaviyo = true
          }
          if (userKlaviyo?.email) {
            userKlaviyo.email = userKlaviyo.email.toLowerCase()
          }
        } catch (error: any) {
          Analytics.error(error)
          Logger.error('Error on SIB api get user klaviyo', { error })
        }
      }

      const userTraits = Analytics.getUserTraits()
      const anonymousId = Analytics?.getAnonymousId() || ''

      // list email
      let listEmail = userTraits?.listEmail || []
      if (listEmail.length) {
        listEmail = listEmail.map((email: string) => email.toLowerCase())
      }
      let userEmail = {}
      let shouldGetUserIdentifiedByEmail = false
      if (userKlaviyo?.email) {
        if (!listEmail.length || !listEmail.includes(userKlaviyo.email)) {
          listEmail.push(userKlaviyo.email)
          shouldGetUserIdentifiedByEmail = true
        }
        listEmail = listEmail.slice(-5) // get the 5 newest items
        userEmail = {
          email: userKlaviyo.email,
          listEmail,
        }
      }

      // list ip
      let listIp = userTraits?.listIp || []
      if (!listIp.length || !listIp.includes(location.ip)) {
        listIp.push(location.ip)
      }
      listIp = listIp.slice(-5) // get the 5 newest items
      let userIpLocation = { ip: location.ip, typeIp: location?.type, listIp }

      // list user agent
      const getProductUserAgent = () => {
        const userAgentString = navigator.userAgent
        return userAgentString.split(')', 1)[0] + ')'
      }
      const currentUserAgent = getProductUserAgent()
      let listUserAgent = userTraits?.listUserAgent || []
      if (!listUserAgent.length || !listUserAgent.includes(currentUserAgent)) {
        listUserAgent.push(currentUserAgent)
      }
      listUserAgent = listUserAgent.slice(-5)
      let userAgents = { userAgent: currentUserAgent, listUserAgent }

      let userIdIdentified = userStore.userIdIdentified // get user id identified in cache
      let traits = {}
      let reidentifyNeeded = false
      if (userTraits?.ip != location.ip) reidentifyNeeded = true

      // check and add checkoutId into user traits
      let checkout: any = {}
      const cookier = new CookieManager()
      const checkoutIdFromCookie = cookier.getCookie(CACHE_KEY_CHECKOUT_ID)
      if (checkoutIdFromCookie) {
        checkout = { checkoutId: checkoutIdFromCookie }
      }
      if (userTraits?.checkoutId != checkoutIdFromCookie) {
        reidentifyNeeded = true
      }

      // add fbc into user traits
      let fbc: any = {}
      const cookieFBC = cookier.getCookie('_fbc')
      const userTraitsFBC = userTraits?._fbc
      if (cookieFBC && cookieFBC != userTraitsFBC) {
        const shouldReIdentifyFBC = userTraitsFBC
          ? shouldIdentifyFBC(userTraitsFBC, cookieFBC)
          : true
        if (shouldReIdentifyFBC) {
          fbc = { _fbc: cookieFBC }
          reidentifyNeeded = true
        }
      }

      // add firstPage into user traits
      const firstPageKey = '_firstPage'
      const firstPagePayload: any = {}
      const currentUserTraits = Analytics.getUserTraits()
      if (currentUserTraits) {
        const firstPage = currentUserTraits[firstPageKey]
        if (firstPage) firstPagePayload[firstPageKey] = firstPage
        else firstPagePayload[firstPageKey] = window.location.href
      }

      if (!userIdIdentified || shouldGetUserIdentifiedByEmail) {
        try {
          const user = await userStore.getUserIdentified(
            location.ip,
            userKlaviyo?.email,
            currentUserAgent
          )
          if (!user?.id) {
            Analytics.identify(anonymousId, {
              ...checkout,
              ...userIpLocation,
              ...userAgents,
              ...userEmail,
              ...fbc,
              ...firstPagePayload,
            })
            return
          }
          userIdIdentified = user.id
          if (user?.traits) {
            traits = removePropertiesStartWithPrefix(user.traits, '$')

            // we use field checkoutId so remove field checkout_id in traits
            if (traits?.checkout_id) {
              traits.checkoutId = traits.checkout_id
              delete traits.checkout_id
            }
          }

          // merge list email
          if (
            user?.traits?.listEmail?.length &&
            (userKlaviyo?.email || user?.traits?.email)
          ) {
            let listEmailMerged = user.traits.listEmail || []
            if (listEmailMerged.length) {
              listEmailMerged = listEmailMerged.map((email: string) =>
                email.toLowerCase()
              )
            }
            listEmail.forEach((email: string) => {
              if (!listEmailMerged.includes(email)) {
                listEmailMerged.push(email)
              }
            })
            userEmail = {
              email: userKlaviyo?.email || user.traits?.email,
              listEmail: listEmailMerged.slice(-5),
            }
          }

          // merge list ip
          if (user?.traits?.listIp?.length) {
            const listIpMerged = user.traits.listIp || []
            listIp.forEach((ip: string) => {
              if (!listIpMerged.includes(ip)) {
                listIpMerged.push(ip)
              }
            })
            userIpLocation = {
              ip: location.ip,
              typeIp: location?.type,
              listIp: listIpMerged.slice(-5),
            }
          }

          // merge list user agent
          if (user?.traits?.listUserAgent?.length) {
            const listUserAgentMerged = user.traits.listUserAgent || []
            listUserAgent.forEach((userAgent: string) => {
              if (!listUserAgentMerged.includes(userAgent)) {
                listUserAgentMerged.push(userAgent)
              }
            })
            userAgents = {
              userAgent: currentUserAgent,
              listUserAgent: listUserAgentMerged.slice(-5),
            }
          }

          if (user?.traits[firstPageKey]) {
            firstPagePayload[firstPageKey] = user?.traits[firstPageKey]
          }
          reidentifyNeeded = true
        } catch (error: any) {
          // do nothing
        }
      }

      if (reidentifyNeeded) {
        Analytics.identify(
          userIdIdentified,
          {
            ...checkout,
            ...traits,
            ...userIpLocation,
            ...userAgents,
            ...userEmail,
            ...fbc,
            ...firstPagePayload,
            externalId: userIdIdentified,
          },
          {
            externalId: [
              {
                id: userIdIdentified,
                type: TIKTOK_EXTERNAL_ID_TYPE,
              },
            ],
          }
        )
      }
    }
  })
}

function shouldIdentifyFBC(userTraitsFBC: string, cookieFBC: string) {
  const getTimestamp = (fbc: string) => {
    const parts = fbc.split('.')
    return parts.length > 2 ? parseInt(parts[2]) : null
  }
  const userTraitsFBCTimestamp = getTimestamp(userTraitsFBC)
  const cookieFBCTimestamp = getTimestamp(cookieFBC)
  if (userTraitsFBCTimestamp && cookieFBCTimestamp) {
    return cookieFBCTimestamp > userTraitsFBCTimestamp
  }
  return false
}

function removeMixPanelCookie() {
  // Temporarily handle cookie overflow by deleting mixpanel cookies
  setTimeout(() => {
    try {
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i]
        const eqPos = cookie.indexOf('=')
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie.trim()

        if (name.trim().startsWith('mp_')) {
          const cookier = new CookieManager()
          cookier.deleteCookie(name.trim(), {
            domain: '.' + routeStore.currentDomain,
            path: '/',
          })
        }
      }
    } catch (error) {
      // do nothing
    }
  }, 5000)
}

function removeFieldKlaIdCookie() {
  // Delete 2 unnecessary fields to avoid cookie overflow
  let time = 0
  let interval = setInterval(() => {
    try {
      if (time >= 30) {
        clearInterval(interval)
        return
      }
      time = time + 1
      const keyKlaId = '__kla_id'
      const cookier = new CookieManager()
      const klaIdCookie = cookier.getCookie(keyKlaId)
      const klaIdCookieEncode = atob(klaIdCookie)

      if (
        !klaIdCookieEncode.includes('$referrer') &&
        !klaIdCookieEncode.includes('$last_referrer')
      ) {
        return
      }

      const klaIdCookieObject = JSON.parse(klaIdCookieEncode)
      const newKlaIdCookie = removeReferrerFields(klaIdCookieObject)
      const newKlaIdCookieEnCode = btoa(JSON.stringify(newKlaIdCookie))
      const date = new Date()
      date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
      const expires = '; expires=' + date.toGMTString()

      const currentDomain = routeStore.currentDomain
      document.cookie =
        keyKlaId +
        '=' +
        newKlaIdCookieEnCode +
        expires +
        ';domain=.' +
        currentDomain +
        '; path=/'

      clearInterval(interval)
    } catch (error) {
      // do nothing
    }
  }, 1000)
}

function removeReferrerFields(obj: any) {
  const fieldsToRemove = ['$referrer', '$last_referrer']
  for (let field of fieldsToRemove) {
    if (obj.hasOwnProperty(field)) {
      delete obj[field]
    }
  }
  return obj
}

async function applyGreetingCardFlow() {
  const abtestVariantMediaFlow =
    getABTestActivedVariantByCode(AB_TEST_MEDIA_FLOW)

  const greetingCardQuery = route.query[QUERY_STRING_GREETING_CARD] as string
  const greetingCardCacheKey = QUERY_STRING_GREETING_CARD
  const cacheResponse = await CacheService.instance?.get(greetingCardCacheKey)
  const greetingCardCache = getJSONfromCacheResponse(cacheResponse)

  if (
    greetingCardQuery == 'true' ||
    greetingCardCache == 'true' ||
    (settingStore.shop?.mediaFlow &&
      (!abtestVariantMediaFlow ||
        abtestVariantMediaFlow == AB_TEST_VARIANT_ENABLE))
  ) {
    mediaCampaignStore.greetingCardFlow = true
  }

  if (greetingCardQuery == 'true' && !greetingCardCache) {
    CacheService.instance?.set(
      greetingCardCacheKey,
      greetingCardQuery,
      SEVEN_DAYS_IN_MILLISECONDS
    )
  }

  // tracking abTest media flow
  if (abtestVariantMediaFlow) {
    const payload = {
      name: AB_TEST_MEDIA_FLOW,
      variant: abtestVariantMediaFlow,
    }

    Analytics.track(
      EVENT_ABTEST_ASSIGNED,
      payload,
      destinationIntegrations([MIXPANEL_DESTINATION])
    )
  }
}

async function applyKolCampaign() {
  const kolCampaignCodeQuery = route.query[
    QUERY_STRING_KOL_CAMPAIGN_CODE
  ] as string

  const kolCampaignCodeCacheKey = QUERY_STRING_KOL_CAMPAIGN_CODE
  const cacheResponse = await CacheService.instance?.get(
    kolCampaignCodeCacheKey
  )
  const kolCampaignCodeCache = getJSONfromCacheResponse(cacheResponse)

  if (kolCampaignCodeCache || kolCampaignCodeQuery) {
    mediaCampaignStore.kolCampaignCode =
      kolCampaignCodeQuery || kolCampaignCodeCache
  }

  if (kolCampaignCodeQuery) {
    CacheService.instance?.set(
      kolCampaignCodeCacheKey,
      kolCampaignCodeQuery,
      SEVEN_DAYS_IN_MILLISECONDS
    )
  }
}

// Integrate Freshchat after Analystics is ready
Analytics.excuteOnReady(integrateFreshchat)

function integrateFreshchat() {
  if (!settingStore.freshchat) return
  FreshchatService.init(settingStore.freshchat)
  FreshchatService.excuteOnReady(exchangeUserInformation)
  FreshchatService.excuteOnUserCreated(exchangeUserInformation)
}

async function exchangeUserInformation() {
  // get user rudderstack
  const userTraits = Analytics.getUserTraits()

  const rudderStackUser = {
    email: userTraits?.email,
    name: userTraits?.name,
  }

  const fcUser = await FreshchatService.getUser()

  // update user information rudderstack
  if (
    (!rudderStackUser.email && fcUser?.email) ||
    (!rudderStackUser.name && fcUser?.name)
  ) {
    const user = {
      ...omitFalsyValue(fcUser),
      ...omitFalsyValue(rudderStackUser),
    }
    Analytics.identify(user)
    Analytics.track(EVENT_USER_SUBSCRIBE, user)
  }

  // update user information freshchat
  if (
    (!fcUser?.email && rudderStackUser.email) ||
    (!fcUser?.name && rudderStackUser.name)
  ) {
    FreshchatService.identify({
      ...omitFalsyValue(rudderStackUser),
      ...omitFalsyValue(fcUser || {}),
    })
  }
}

// identify user when submiting klaviyo form
Analytics.excuteOnReady(identifyUserSubmitingKlaviyoForm)

function identifyUserSubmitingKlaviyoForm() {
  // default fields of klaviyo dont need tracking these fields
  const withoutFieldsIdentify = [
    '$consent_form_id',
    '$consent_form_version',
    '$consent_method',
    '$source',
  ]
  const fieldKlaviyoPrefix = '$'
  const formEventType = 'submit'

  window.addEventListener('klaviyoForms', function (event: any) {
    if (event.detail.type == formEventType) {
      const userKlaviyo: { [key: string]: string } = {}

      Object.keys(event.detail.metaData).forEach((key) => {
        if (!withoutFieldsIdentify.includes(key)) {
          const property = key.startsWith(fieldKlaviyoPrefix)
            ? key.slice(fieldKlaviyoPrefix.length)
            : key
          userKlaviyo[property] = event.detail.metaData[key]
        }
      })

      if (!Object.keys(userKlaviyo).length) return

      const userTraits = Analytics.getUserTraits()

      const listEmail = userTraits?.listEmail || []
      if (
        userKlaviyo?.email &&
        (!listEmail?.length || !listEmail.includes(userKlaviyo?.email))
      ) {
        listEmail.push(userKlaviyo?.email)
      }

      let shouldIdentifyUser = false

      Object.keys(userKlaviyo).forEach((key) => {
        if (
          JSON.stringify(userKlaviyo[key]) !== JSON.stringify(userTraits[key])
        ) {
          shouldIdentifyUser = true
        }
      })

      if (shouldIdentifyUser) {
        const user = { ...userTraits, ...userKlaviyo, listEmail }
        Analytics.identify(user)
        Analytics.track(EVENT_USER_SUBSCRIBE, user)
      }
    }
  })
}

function mockShopifyEnviroment() {
  // mock Shopify object for customily app
  window.Shopify = {
    shop: import.meta.env.VITE_SHOPIFY_DOMAIN as string,
    routes: { root: '/' },
  }
}

function handleNotFoundPage() {
  watch(isThereAnyPageNotFound, () => {
    setPageTitle(PAGE_NOT_FOUND_TITLE)
  })
}

function mesurePerformance() {
  let isTrackedFirstInput = false
  // Capture first input from the user
  ;['keydown', 'click', 'touchstart', 'scroll'].forEach((type) => {
    addEventListener(
      type,
      () => {
        if (!isTrackedFirstInput) {
          // Analytics.track(
          //   EVENT_USER_FIRST_INPUT,
          //   {
          //     performance_time: performance.now(),
          //   },
          //   destinationIntegrations([MIXPANEL_DESTINATION])
          // )
          isTrackedFirstInput = true
        }

        if (deviceStore.firstInputFired) return
        deviceStore.firstInputFired = true
      },
      { once: true, capture: true }
    )
  })

  setTimeout(() => {
    deviceStore.firstInputFired = true
  }, maximumTimeToWaitFirstInput)
}

function watchShowHidePopup() {
  // add class to body when there is atlease one popup showing
  watch(
    deviceStore.showingPopups,
    (showingPopups) => {
      if (showingPopups && showingPopups.length) {
        document.body.classList.add(
          'body--popup-showing',
          deviceStore.isMobile ? 'mobile' : 'desktop'
        )
      } else {
        document.body.classList.remove('body--popup-showing')
      }
    },
    { immediate: true }
  )

  router.afterEach((to, from) => {
    if (to.path === from.path) return
    // hide all popup in case route change
    while (deviceStore.showingPopups.length) {
      deviceStore.showingPopups.pop()
    }
  })
}

function watchShowHidePromotionBar() {
  // add class to body when there is atlease one popup showing
  watch(
    () => componentStore.promotionBar.isShow,
    (isShow) => {
      if (isShow) {
        document.body.classList.add('body--promotion-bar-showing')
      } else {
        document.body.classList.remove('body--promotion-bar-showing')
      }
    },
    { immediate: true }
  )
}

// set page title
function watchPageTitle() {
  watch(
    () => routeStore.pageTitle,
    (value) => {
      document.title = value
    },
    { immediate: true }
  )
}

function trackingPageEvents() {
  // Tracking first time load the page
  Analytics.page(document.title || (route.meta?.pageName as string), {
    path: window.location.pathname,
    href: window.location.href,
  })

  router.afterEach((to, from) => {
    if (to.path === from.path) return
    routeStore.loadType = CSR_LOAD_TYPE
    performance.mark(TIME_MARK_CSR_LOAD_PAGE)

    // tracking page
    Analytics.page(document.title || (to.meta?.pageName as string) || '', {
      path: window.location.pathname,
      href: window.location.href,
    })
    FreshchatService.trackPage(
      window.location.href,
      document.title || (to.meta?.pageName as string) || ''
    )
  })
}

function setCurrentUrl() {
  watch(
    () => route.fullPath,
    () => {
      routeStore.currentUrl = window.location.href
    }
  )
}

function toggleProgressBarOnRouterChange() {
  router.beforeEach((to, from) => {
    if (to.path === from.path) {
      return
    }

    EventBus.trigger(EVENT_APP_PROGRESS_START)
  })
  router.afterEach((to, from) => {
    if (to.path === from.path) return
    EventBus.trigger(EVENT_APP_PROGRESS_FINISH)
  })
}

function trackUnloadEvents() {
  window.addEventListener(
    'pagehide',
    (event) => {
      Analytics.track(
        EVENT_TRACKING_PAGE_HIDE,
        {
          persisted: event.persisted,
        },
        destinationIntegrations([MIXPANEL_DESTINATION])
      )
    },
    false
  )
}

function trackingUserScroll() {
  const debounceScroll = debounce(() => {
    Analytics.track(
      EVENT_TRACKING_USER_SCROLL,
      {
        current_scroll: document.documentElement.scrollTop,
      },
      destinationIntegrations([MIXPANEL_DESTINATION])
    )
  }, 500)

  window.addEventListener('scroll', debounceScroll, { passive: true })
}

function trackingWebVitals(
  event: string,
  data: WebVitals.Metric,
  path: string
) {
  try {
    Analytics.track(
      event,
      {
        value: data.value,
        path: path,
      },
      destinationIntegrations([MIXPANEL_DESTINATION])
    )
  } catch (err: any) {
    Analytics.error(err)
  }
}

function trackingPerformance() {
  WebVitals.getCLS((event) => {
    trackingWebVitals(EVENT_TRACKING_PERFORMANCE_CLS, event, route.path)
  })

  WebVitals.getFID((event) => {
    trackingWebVitals(EVENT_TRACKING_PERFORMANCE_FID, event, route.path)
  })

  WebVitals.getLCP((event) => {
    trackingWebVitals(EVENT_TRACKING_PERFORMANCE_LCP, event, route.path)
  })

  WebVitals.getFCP((event) => {
    trackingWebVitals(EVENT_TRACKING_PERFORMANCE_FCP, event, route.path)
  })

  WebVitals.getTTFB((event) => {
    trackingWebVitals(EVENT_TRACKING_PERFORMANCE_TTFB, event, route.path)
  })
}

function trackingPerformanceAppMounted() {
  Analytics.track(
    EVENT_TRACKING_APP_MOUNTED,
    {
      performance_time: performance.now(),
    },
    destinationIntegrations([MIXPANEL_DESTINATION])
  )
}

// After visit a number of personalized product page.
// We need to refresh the page because Customily is fucking dumb.
// It will add alot of watcher and varible so our client browser may be run out of memory
function useSSRAfterVisitingManyProductPages() {
  const thresholdToRefreshPage = 3
  router.beforeEach((to, from) => {
    if (to.path === from.path) return

    if (
      productStore.numberOfPersonalizedProductVisited < thresholdToRefreshPage
    ) {
      return
    }

    // when we update window location href, javascript is still running
    // in case the user click back button, vue router will call history.go(1)
    // to revert the URL if we cancel the beforeEach hook
    // so we need to run this code after vue router code to make it work
    setTimeout(() => {
      window.location.href = to.fullPath
    })

    // return false to cancel router navigation
    // prevent other hooks got called
    // and prevent page trasistion animation
    return false
  })
}

function handleRouteError() {
  // handle router error
  router.onError((error, to, from) => {
    Logger.error('Navigation error', { error, to, from })
    Analytics.track(
      EVENT_TRACKING_NAVIGATION_ERROR,
      {
        error: error?.message || error,
        to: to.fullPath,
        from: from.fullPath,
      } as any,
      () => {
        if (isOnServer) return
        // in case there is an error on client side when navigating, use server redirect instead
        // an usecase is requesting a page after ugrading app version
        window.location.href = to.fullPath
      }
    )
  })
}
</script>
